<template>
    <div>
        <b-card>
            <ValidationObserver ref="validation-observer">
                <div class="col-12 d-flex flex-wrap p-0">
                    <!--   USERNAME   -->
                    <b-col cols="12" class="p-0 px-1">
                        <ValidationProvider
                            name="Год"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group label="Год" label-for="name">
                                <b-form-input
                                    type="number"
                                    v-model="name"
                                    id="name"
                                    size="md"
                                    placeholder="Введите год"
                                />
                            </b-form-group>
                            <p v-if="errors" class="validation__red">
                                {{ errors[0] }}
                            </p>
                        </ValidationProvider>
                        <ValidationProvider
                            name="Модель"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-form-group label="Модель" label-for="">
                                <b-form-select
                                    disables="!options.length"
                                    v-model="model"
                                    :options="options"
                                ></b-form-select>
                            </b-form-group>

                            <p v-if="errors" class="validation__red">
                                {{ errors[0] }}
                            </p>
                        </ValidationProvider>
                    </b-col>
                </div>
            </ValidationObserver>

            <b-button
                class="btn-success float-right mt-2 mr-1"
                @click="updateYear"
            >
                Сохранить
            </b-button>
        </b-card>
    </div>
</template>

<script>
import api from "@/services/api";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BCol,
    BTable,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormSelect,
} from "bootstrap-vue";

export default {
    name: "AppYearsUpdate",
    components: {
        BFormGroup,
        BFormInput,
        BButton,
        BCard,
        BCol,
        BTable,
        BFormCheckbox,
        BFormCheckboxGroup,
        BFormSelect,

        ToastificationContent,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            name: null,
            model: null,
            options: [],
            isBusy: false,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            selectMode: "multi",
            infoModal: {
                id: "info-modal",
                title: "",
                content: "",
            },
        };
    },

    async mounted() {
        this.getOneYear();
        this.getAllModels();
    },

    methods: {
        async getOneYear() {
            const { id } = this.$route.params;
            await api.years
                .fetchOneYear(id)
                .then((res) => {
                    this.name = res.data.name;
                    this.model = res.data.model.id;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async getAllModels() {
            api.common.getModels().then(({ data }) => {
                const options = data
                    .filter((el) => el.active)
                    .map((el) => ({ value: el.id, text: el.name }));
                this.options = [
                    { value: null, text: "Выберите Модель", disabled: true },
                    ...options,
                ];
                this.brand = null;
            });
        },
        showToast(variant, text, icon) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: text,
                    icon: icon,
                    variant,
                },
            });
        },

        async updateYear() {
            const isValid = await this.$refs["validation-observer"].validate();
            if (isValid) {
                const { id } = this.$route.params;
                const { name } = this;
                const data = {
                    name,
                    model_id: this.model,
                };
                api.years
                    .updateYear(id, data)
                    .then(() => {
                        this.$router.push({ name: "years" });
                        this.showToast(
                            "success",
                            "Успешно изменено!",
                            "CheckIcon"
                        );
                    })
                    .catch((error) => {
                        console.error(error);
                        this.showToast(
                            "danger",
                            "Что-то пошло не так!",
                            "XIcon"
                        );
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
